import React, { useEffect, useState } from "react";
import { CContainer, CRow, CCol, CButton } from "@coreui/react";
import { CTable, CTableBody, CTableRow, CTableDataCell } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

//-------- components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyRight from "../components/CopyRight";
import VideoPlayer from "../components/VideoPlayer";
import UserMedia from "../components/UserMedia";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "../components/loader/Spinner";

//--- images
import videoPlaceHolder from '../assets/images/novideo-thumbnail.png';
import awardIcon from "../assets/images/award-icon.svg";
import userAvatar from '../assets/images/user-avatar.jpg';

function Profile() {

  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { userid } = useParams();
  const loggedInUserId = localStorage.getItem('userid');
  const language = localStorage.getItem("language");
  const [loggedIn, setLoggedIn] = useState(false);
  const [awards, setAwards] = useState([]);
  // console.log("User ID:", userid);
  // console.log("Loged in User ID:", loggedInUserId);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);  

  const handleNavigate = () => {
    if(isLoggedIn)
    {
      if (loggedInUserId === userid) {
        toast.error("You can't pitch your own profile!");
      }else{
        navigate(`/pitch-profile/${userid}`);
      }
    }else{
      navigate(`/login`);
      toast.error(t('Please login before pitching!'));
    }
  }

  const handleNavigate2 = () => {
    if(isLoggedIn){
      navigate(`/chat-screen/${userid}`);
    }else{
      toast.error('Please Login before connect to other users!');
    }
  }

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  //---- fetch Upcoming Events
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const language = localStorage.getItem("language");
      try {
        const response = await fetch(`${apiUrl}/event/upcoming`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'accept-language': language
          },
          body: JSON.stringify({
            user_id: userid,
          }),
        });

        const data = await response.json();
        setUpcomingEvents(data.data);

        if (!response.ok) {
          console.log(data.message);
        }

        if (data.result === "success") {
          // console.log(data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEvents();
  }, []);

  // ---- fetch user
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //--- profile picture update handling
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");

  const [aboutTxt, setAboutTxt] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isActive, setIsActive] = useState(false);

  //--- video update handling
  const [profileVideo, setProfileVideo] = useState(null);
  const imgeUrl = process.env.REACT_APP_IMAGE_URL;

  //---- Fetching Roles
  const roleOptions = userRoles.map((role) => ({
    value: role._id,
    label: role.name,
  }));

  useEffect(() => {
    const fetchRoles = async () => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const language = localStorage.getItem("language");
      try {
        const response = await fetch(`${apiUrl}/roles`, {
          method: "GET",
          headers:{
            'accept-language': language
          }
        });

        const data = await response.json();
        setUserRoles(data.data);

        if (!response.ok) {
          console.log("Network response was not ok");
          // toast.error(data.message);
        }

        if (data.result === "success") {
          // console.log(data.message);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);

  //--- fetching user data
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState("");

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/get-user`,
        {
          user_id: userid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            'accept-language': language
          },
        }
      );

      if (response.data.result === "success") {
        setUserData(response.data.data);
        // console.log("User data fetched successfully:", response.data.data);
        // console.log(response.data.message);
        setLoading(false);
        if(response.data.data.userDetail){
          setProfilePicture(
            `${imgeUrl}/${response.data.data.userDetail.profile_image}`
          );
          setProfileVideo(
            `${response.data.data.userDetail.profile_video}`
          );
          // console.log(response.data.data.userDetail.profile_video);
          setProfilePictureUrl(
            `${response.data.data.userDetail.profile_image}`
          );

          setAboutTxt(response.data.data.userDetail.about);
          setSelectedRoles(response.data.data.userDetail.roles);
        }

        setAwards(response.data.data.awards);
        // console.log('awards : ', awards);
        setUserName(response.data.data.first_name +" " + response.data.data.sir_name);

        if (response.data.data.status === 'Active') {
          setIsActive(true);
          // console.log(response.data.data.status, ": user status", isActive);
        }
      } else {
        setLoading(false);
        throw new Error(response.data.message || "Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
      // toast.error(`${error.message}`);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'yyyy-MM-dd, hh:mm a');
  };

  return (
    <>
      {/*/--- navbar ---/*/}
      <Navbar />

      {/* bio section */}
      <section className="user-profile-wrapper position-relative">
        {loading?
        <div className="loader-wrapper">
          <Spinner/>
        </div>
        :<></>
        }
        <CContainer>
          <CRow>
            <CCol lg={4}>
              <div className="profile-banner">
                <div className="profile-banner-wrapper">
                  <div className="profile-banner-wrapper-content">
                    <div className="user-profile-picture text-center mx-auto">
                      {profilePictureUrl && profilePictureUrl !== 'null'? 
                        <img src={`${imgeUrl}/${profilePictureUrl}`} alt="Profile" />
                        :
                        <img src={userAvatar} alt="avatar"/>
                      }
                    </div>
                    <div className="user-bio w-100">
                      <h2 className="user-name text-center">{userName}</h2>
                      <div className="user-role-wrapper">
                        <div>
                          <ul className="user-profile-roles-wrapper text-center">
                            {selectedRoles.map((role, index) => (
                              <li
                                key={index}
                                className="user-profile-roles-show"
                              >
                                {/* {role.name} */}
                                {role.name}
                                {index < selectedRoles.length - 1 && 
                                <svg height="15" width="10">
                                    <line x1="5" y1="0" x2="5" y2="100" style={{stroke:'#ccc',strokeWidth:'2'}} />
                                </svg>} {" "}
                              </li> 
                            ))}
                          </ul>
                        </div>
                        
                      </div>
                    </div>

                    {/*/--- User Awards ---/*/}
                    {awards.length > 0 && (
                      <div className="user-awards mt-5">
                        <div className="award-info mx-auto">
                         
                            <div className="awards-icon">
                              <img src={awardIcon} alt="award" />
                                <div className="awards-desc-wrapper">
                                  {awards.map((award) => (
                                    <div key={award._id} className="position-relative award-details">
                                      <div className="award-title">{award.title}</div>
                                      <div className="awards-desc">
                                        {award.description}
                                      </div>
                                      <div className="award-pointer"></div>
                                    </div>
                                  ))}
                                </div>
                            </div>
                         
                        </div>
                        <div className="text-center award-add-btn">
                          <p className="mb-0">{t('Awards')}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CCol>
            <CCol lg={8}>
              <div className="user-profile-clip-wrap mt-4 mt-lg-0">
                {profileVideo !== 'null' && profileVideo !== null && profileVideo !== undefined ? 
                  <VideoPlayer videoUrl={`${imgeUrl}/${profileVideo}`} width="100%" />
                  :
                  <div className="no-video-wrapper video-frame mb-3">
                    <img src={videoPlaceHolder} alt="no video"/>
                  </div>
                }
              </div>

              {userid === loggedInUserId?
                <></>
                :
                <>
                  {isActive ?
                    <>
                      <div className="mb-4">
                        <CButton
                          className="primary_outline_btn"
                          onClick={handleNavigate}
                        >
                          {t('pitch your vision')}
                        </CButton>

                        {isLoggedIn?
                          <CButton
                            className="primary_outline_btn mt-3"
                            onClick={handleNavigate2}
                          >
                            {t('Connect')}
                          </CButton> : <></>
                        }
                      </div>
                        
                    </>
                  :
                    <></>
                  }
                </>
                
              }

              <div className="user-about">
                <h3>{t('About')}</h3>
                <p>{aboutTxt}</p>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>

      {/*/--- event section ---/*/}

      <section className="event-wrapper">
        <CContainer>
          <CRow>
            <CCol md={6} xs={6}>
              <p className={"event_title"}>
                {t('My Upcoming Event')}
              </p>
            </CCol>
            <CCol lg={12}>
                {/* //----- table for uncoming events */}
                <div className="table-wrapper upcoming mt-5">
                  <CTable responsive>
                    <CTableBody>
                      {upcomingEvents.map((event) => (
                        <CTableRow key={event._id}>
                          <CTableDataCell>
                            <span>{t('with')} {event.user_id.first_name}</span>
                          </CTableDataCell>
                          <CTableDataCell>
                            <span>{formatDateTime(event.datetime)}</span>
                          </CTableDataCell>
                          <CTableDataCell>
                            <span>{t('for')} "{event.film_name}"</span>
                          </CTableDataCell>
                          <CTableDataCell>
                            <Link>{event.link}</Link>
                          </CTableDataCell>
                        </CTableRow>
                      ))}

                      {upcomingEvents.length < 1? 
                        <div style={{ background: 'transparent' }}>
                          <span className="text-white" style={{ background: 'transparent' }}>{t('No Record Found!')}</span>
                        </div>
                        :
                        <></>
                      }
                    </CTableBody>
                  </CTable>
                </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>

      <UserMedia />
     
      {/*/--- Footer ---/*/}
      <Footer />

      {/*/--- copy right ---/*/}
      <CopyRight />
    </>
  );
}

export default Profile;
