import React from 'react';
import { Link, useParams } from 'react-router-dom';

function ProfileCard(props) {

    const userId = props.userId;
    // console.log('userId = ', userId);
    
    return (
        <>
            <Link to={`${props.link}`} style={{textDecoration:'none'}}>
                <div className='profile-card'>
                    <div className='profile-card-img'>
                        <img src={props.imgSrc} alt='profile'/>
                    </div>
                    <div className='profile-card-footer'>
                        {props.title}
                    </div>
                </div>
            </Link>
        </>
    )
}

export default ProfileCard;
