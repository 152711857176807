import React, { useEffect, useState, useRef  } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { Link, useParams } from "react-router-dom";
import { io } from 'socket.io-client';
import axios from "axios";
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import avatar from '../assets/images/profile_avatar.png';
import Spinner from './loader/Spinner';
import './loader/Spinner.css';
import { COffcanvas, COffcanvasBody, COffcanvasHeader, CCloseButton } from '@coreui/react';
import { CButton } from '@coreui/react';


function UserChatScreen() {

  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { userid } = useParams();
  const [receiverId, setReceiverId] = useState(userid); // receiver id
  const senderId = localStorage.getItem('userid'); // sender id
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatThreads, setChatThreads] = useState([]);
  // const socket = io("https://api.cinewoo.com:7000/");
  const socketUrl = process.env.REACT_APP_Socket_URL;
  const socket = io(`${socketUrl}`);
  const [senderImg, setSenderImg] = useState('');
  const [receiverImg, setReceiverImg] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const imgeUrl = process.env.REACT_APP_IMAGE_URL;
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const language = localStorage.getItem("language");
  const [activeThreadId, setActiveThreadId] = useState(null);

  const chatCardBodyRef = useRef(null);
  //--- for loaders
  const [isChatHistoryLoading, setIsChatHistoryLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isChatThreadsLoading, setIsChatThreadsLoading] = useState(false);
  const [messageBeingSent, setMessageBeingSent] = useState(null);

  useEffect(() => {
    // Scroll to the bottom on component mount
    if (chatCardBodyRef.current) {
      chatCardBodyRef.current.scrollTop = chatCardBodyRef.current.scrollHeight;
    }
  }, [messages]);
  

  // fetching sender user data start here
  const fetchUserData = async (userId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${apiUrl}/get-user`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            'accept-language': language
          },
        }
      );

      if (response.data.result === "success") {
        console.log(response.data.message);

        if (response.data.data.userDetail) {
          setReceiverImg(
            `${response.data.data.userDetail.profile_image}`
          );
        }

        setReceiverName(response.data.data.first_name + " " + response.data.data.sir_name);
      } else {
        throw new Error(response.data.message || "Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  // fetching sender user data end' here

  //--- fetching chat history
  const fetchChatHistory = (senderId, receiverId) => {
    setIsChatHistoryLoading(true);
    socket.emit("get-chat-history", {
      sender_id: senderId,
      receiver_id: receiverId,
    });

    socket.emit("register", {
      user_id: senderId,
    });
  };

  useEffect(() => {
    const userImg = localStorage.getItem("userImg");
    setSenderImg(`${userImg}`);
    fetchUserData(userid);
  }, [userid]);

  //---- socket connection
  useEffect(() => {
    window.scrollTo(0, 0);

    const connectSocket = () => {
      console.log("Socket connected:", socket.id);
      setIsChatThreadsLoading(true);
      socket.emit("get-chat-threads", { user_id: senderId });
      fetchChatHistory(senderId, receiverId);
    };

    socket.on("connect", connectSocket);

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socket.on(`register`, (senderId) => {
      console.log(`${senderId} register event received`);
    });

    socket.on(`${senderId}-send-message`, (message) => {
      console.log(`${senderId}-send-message event received with message:`, message);
      console.log(message.data.data.sender_id === receiverId , message.data.data.sender_id === senderId);
      socket.emit("get-chat-threads", { user_id: senderId });

      setIsSendingMessage(false);
      if(message.data.data.sender_id === senderId)
        {
          socket.emit("get-chat-threads", { user_id: senderId });
          return false;
        }
      if (message.data.data.sender_id === receiverId) {
        console.log("sss",message.data.data.message);
        setMessages((prevMessages) => [...prevMessages, message.data.data]);
        socket.emit("get-chat-threads", { user_id: senderId });
        // setMessages(message.data.data);
      }

      if (chatCardBodyRef.current) {
        chatCardBodyRef.current.scrollTop = chatCardBodyRef.current.scrollHeight;
      }
    
    });

    socket.on(`${senderId}-${receiverId}-get-chat-history`, (response) => {
      console.log(`${senderId}-${receiverId}-get-chat-history event received with response:`, response);
      setIsChatHistoryLoading(false);
      if (response.result === "success" && Array.isArray(response.data)) {
        setMessages(response.data);
      }
    });

    socket.on(`${senderId}-get-chat-threads`, (response) => {
      console.log(`${senderId}-get-chat-threads event received with response:`, response);
      setIsChatThreadsLoading(false);
      if (response.result === "success" && Array.isArray(response.data.data)) {
        setChatThreads(response.data.data);
        console.log('chatThreads : ', response.data);
      }
    });

    return () => {
      socket.off("connect", connectSocket);
      socket.off("disconnect");
      socket.off(`${senderId}-send-message`);
      socket.off(`${senderId}-${receiverId}-get-chat-history`);
      socket.off(`${senderId}-get-chat-threads`);
    };
  }, [senderId, receiverId]);

  //---- send message
  const handleSendMessage = (event) => {
    event.preventDefault();
    if (newMessage) {
      const messageData = {
        sender_id: senderId,
        receiver_id: receiverId,
        message: newMessage,
      };
      setIsSendingMessage(true);
      setMessageBeingSent(messageData);
      console.log('send-message', messageData);
      socket.emit('send-message', messageData);
      socket.emit("get-chat-threads", { user_id: senderId });
      setMessages((prevMessages) => [...prevMessages, messageData]);
      setNewMessage('');
      
    }else{
      console.log('type a message first');
    }
  };

    // Handle incoming messages for the receiver
    // useEffect(() => {
      
    //   if (receiverId) {
    //     socket.on(`${receiverId}-send-message`, (message) => {
    //       console.log(`${receiverId}-send-message event received with message:`, message);
    //       if(message.data.data.sender_id == senderId)
    //       {
    //         return false;
    //       }
  
    //       if (message.data.data.sender_id === receiverId) {
    //         console.log("sss",message.data.data.message);
    //         setMessages((prevMessages) => [...prevMessages, message.data.data]);
    //       }
    //       // setMessages((prevMessages) => [...prevMessages, message.data.data]);
    //     });

    //     if (chatCardBodyRef.current) {
    //       chatCardBodyRef.current.scrollTop = chatCardBodyRef.current.scrollHeight;
    //     }
  
    //     return () => {
    //       socket.off(`${receiverId}-send-message`);
    //     };
    //   }
    // }, [receiverId]);

  //---- calling history on click on a specific thread
  const handleThreadClick = (thread, threadId) => {
    setVisible(false);
    setReceiverId(thread);
    fetchChatHistory(senderId, thread);
    fetchUserData(thread);
    setActiveThreadId(threadId); 
    
  };

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar authorized={true} />
            <section className='chat-screen-wrapper'>
                <CContainer>
                    <div className='chat-screen-content'>
                        <CRow>
                            <CCol md={12}>
                                <h2>{t('Your Messages')}</h2>
                            </CCol>
                            <CCol lg={4} className='mt-5'>
                              <div className='edit-profile-sidebar'>
                                <CButton className="d-lg-none w-100 profile-sidebar-toggler" onClick={() => setVisible(true)}> {t('Available Chats')} »</CButton>
                                <COffcanvas  responsive="lg" expand="lg" dark placement="start" visible={visible} onHide={() => setVisible(false)} backdrop={false}>
                                  <COffcanvasHeader className='justify-content-end'>
                                    <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
                                  </COffcanvasHeader>
                                  <COffcanvasBody>

                                    <div className="users-details-wrapper">
                                      <div className='users-details-content'>
                                        {chatThreads.map(thread => {

                                          let profileImage;
                                          let firstName;
                                          let sirName;
                                          let lastMessage = thread.last_message;
                                          let receiver;
                                          let threadId;


                                          if (thread.sender_id?._id === senderId) { // Use optional chaining to handle null checks
                                            profileImage = thread.receiver_id?.userDetail?.profile_image; // Optional chaining
                                            firstName = thread.receiver_id?.first_name; // Optional chaining
                                            sirName = thread.receiver_id?.sir_name; // Optional chaining
                                            receiver = thread.receiver_id?._id; // Optional chaining
                                            threadId = thread._id;
                                          } else {
                                            profileImage = thread.sender_id?.userDetail?.profile_image; // Optional chaining
                                            firstName = thread.sender_id?.first_name; // Optional chaining
                                            sirName = thread.sender_id?.sir_name; // Optional chaining
                                            receiver = thread.sender_id?._id; // Optional chaining
                                            threadId = thread._id;
                                          }

                                        return (
                                          <div key={thread._id} className={`single-user-detail-card mb-2 ps-2 ${activeThreadId === thread._id ? 'active' : ''}`} onClick={() => handleThreadClick(receiver, threadId)}>
                                            <CRow className='align-items-center h-100-p w-100 m-0'>
                                              <CCol lg={3} xs={3} md={3} sm={3}>
                                                <div className="single-user-dp">
                                                  {profileImage ? (
                                                    <img src={`${imgeUrl}/${profileImage}`} alt="dp" />
                                                  ) : (
                                                    <img src={avatar} alt="dp" />
                                                  )}
                                                </div>
                                              </CCol>
                                              <CCol lg={9} xs={9} md={9} sm={9}>
                                                <div className="single-user-desc">
                                                  <div className="single-user-name">{firstName} {sirName}</div>
                                                  <div className="last-message">{lastMessage}</div>
                                                </div>
                                              </CCol>
                                            </CRow>
                                          </div>
                                        );
                                        })}

                                        {/*/--- loader ---/*/}
                                        {isChatThreadsLoading && 
                                          <Spinner />
                                        }
                                      </div>
                                    </div>

                                  </COffcanvasBody>
                                </COffcanvas>
                              </div>
                            </CCol>
                            <CCol lg={8} className='mt-5'>
                                <div className="users-chats-wrapper">

                                    {/*/--- chat card header start here ---/*/}
                                    <div className="chat-card-header">
                                      <div className="active-user-desc">{receiverName} <span className='active-dot'></span></div>
                                    </div>
                                    {/*/--- chat card header end's here ---/*/}

                                    {/*/--- message content start here ---/*/}
                                    <div className="chat-card-body" ref={chatCardBodyRef}>
                                      {isChatHistoryLoading ? (
                                        <Spinner />
                                      ) : (
                                        <div className="chat-user">
                                          {messages.map((msg, index) => (
                                            <div key={index} className={msg.sender_id === senderId ? 'sender' : 'reciver'}>
                                              {msg.sender_id === senderId ? (
                                                  <>
                                                    {messageBeingSent && messageBeingSent.message === msg.message  && isSendingMessage? 
                                                      <div className='me-3'>
                                                        <Spinner />
                                                      </div>
                                                      : null
                                                    }

                                                    <div className={`sender-message-content me-2`}>
                                                      {msg.message}
                                                    </div>

                                                    <div className='sender-img'>
                                                      {senderImg && senderImg !== 'null' && senderImg !== null?
                                                        <Link to={`/user-profile/${senderId}`}>
                                                          <img src={`${imgeUrl}/${senderImg}`} alt="user" />
                                                        </Link>
                                                        :
                                                        <Link to={`/user-profile/${senderId}`}>
                                                          <img src={avatar} alt="avatar" />
                                                        </Link>
                                                      }
                                                    </div>
                                                  </>
                                              ) : (
                                                <>
                                                    <div className='reciever-img me-2'>
                                                      {receiverImg && receiverImg && receiverImg !== 'null'?
                                                        <Link to={`/user-profile/${receiverId}`}>
                                                          <img src={`${imgeUrl}/${receiverImg}`} alt="user" />
                                                        </Link>
                                                        :
                                                        <Link to={`/user-profile/${receiverId}`}>
                                                          <img src={avatar} alt="avatar" />
                                                        </Link>
                                                      }
                                                    </div>
                                                    <div className="reciever-message-content">
                                                      {msg.message}
                                                    </div>
                                                </>
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      )}

                                        {/*/--- typing input start's here ---/*/}
                                        <form>
                                          <div className="typing-input-wrapper">
                                            <input
                                                type='text'
                                                placeholder={t('Type here...')}
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                            />
                                            <button type='submit' className='send-btn' onClick={(e)=>handleSendMessage(e)}>
                                              {/* {isSendingMessage ? `${t('Sending...')}` : `${t('Send')}`} */}
                                              {t('Send')}
                                            </button>
                                          </div>
                                        </form>
                                        {/*/--- typing input end's here ---/*/}
                                    </div>  

                                </div>
                            </CCol>
                        </CRow>
                    </div>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default UserChatScreen;