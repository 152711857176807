import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import { CButton, CCol, CContainer, CForm, CFormCheck, CFormInput, CFormLabel, CModal, CModalBody, CModalFooter, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import Spinner from "../components/loader/Spinner";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {  toast } from 'react-toastify';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

function Settings() {

    const { t } = useTranslation();
    const [blocking, setBlocking] = useState(false);
    const navigate = useNavigate();
    
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const currentDate = new Date();
    const [roles, setRoles] = useState([]);
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const [isDobModified, setIsDobModified] = useState(false);
    const [language, setLanguage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userDob, setUserDob] = useState('');
    const [industry, setIndustry] = useState('');
    const [otherIndustry, setOtherIndustry] = useState('');
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPasword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);
    const [error2, setError2] = useState(false);
    const [rolesError, setRolesError] = useState(false);
    const [profileComplete, setProfileComplete] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [alertvisible, setAlertVisible] = useState(false);


    //------- start date picker
    // Function to get the maximum number of days in a month
    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const handleDayChange = (e) => {
        const day = parseInt(e.target.value);
        setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day));
        setIsDobModified(true);
    };

    const handleMonthChange = (e) => {
        const month = parseInt(e.target.value);
        const maxDays = getDaysInMonth(month, selectedDate.getFullYear());
        const newDay = selectedDate.getDate() <= maxDays ? selectedDate.getDate() : maxDays;
        setSelectedDate(new Date(selectedDate.getFullYear(), month, newDay));
        setIsDobModified(true);
    };

    const handleYearChange = (e) => {
        const year = parseInt(e.target.value);
        const maxDays = getDaysInMonth(selectedDate.getMonth(), year);
        const newDay = selectedDate.getDate() <= maxDays ? selectedDate.getDate() : maxDays;
        setSelectedDate(new Date(year, selectedDate.getMonth(), newDay));
        setIsDobModified(true);
    };

    const renderDayOptions = () => {
        const maxDays = getDaysInMonth(selectedDate.getMonth(), selectedDate.getFullYear());
        return [...Array(maxDays)].map((_, index) => (
            <option key={index + 1} value={index + 1}>{index + 1}</option>
        ));
    };
    //---------end date picker


    //------ user roles update
    const roleOptions = userRoles.map(role => ({
        value: role._id,
        label: role.name
    }));

    const handleRoleChange = (selectedOptions) => {
        if (selectedOptions.length <= 3) {
            const selectedRoleIds = selectedOptions.map(option => option.value);
            setRoles(selectedRoleIds);
        } else {
          setAlertVisible(true);
        }
    };

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${apiUrl}/roles`,{
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'accept-language': language
                    }
                });

                const data = await response.json();
                setUserRoles(data.data);

                if (!response.ok) {
                    console.log('Network response was not ok');
                }

                if (data.result === 'success') {
                    console.log(data.message);
                }
                
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchRoles();
    }, []);

    //--------- Get User data start
    useEffect(() => {
        setLoading(true);
        const isprofileComplete = localStorage.getItem('profileComplete') === 'complete';
        setProfileComplete(isprofileComplete);
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userid");
        try {
            const response = await axios.post(
                `${apiUrl}/get-user`,
                {
                    user_id: userId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "accept-language": language,
                    },
                }
            );
    
            if (response.data.result === "success") {
                localStorage.setItem('profileComplete', response.data.data.profile_complete);
                setUserId(response.data.data._id);
                setFirstName(response.data.data.first_name);
                setLastName(response.data.data.sir_name);
                if (response.data.data.date_of_birth) {
                    setUserDob(response.data.data.date_of_birth);
                    const dob = new Date(response.data.data.date_of_birth);
                    setSelectedDate(dob);
                }   
                setLanguage(response.data.data.language);
                setIndustry(response.data.data.industry);
                setEmail(response.data.data.email);
                setOtherIndustry(response.data.data.other_industry);
    
                if (response.data.data.userDetail) {
                
                    localStorage.setItem(
                        "userImg",
                        response.data.data.userDetail.profile_image
                    );
        
                    const userRoleIds = response.data.data.userDetail.roles.map(
                        (role) => role._id
                    );
        
                    setRoles(userRoleIds);
                }
            
            } else {
                throw new Error(response.data.message || "Failed to fetch user data");
            }
            setLoading(false);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setLoading(false);
        }
    };
    //--------- Get User data end

    //--------- Update user data start
    const handleUserUpdate = async () => {
        if (industry === 'Other' && otherIndustry === '') {
            setError2(true);
        }else if(roles.length === 0){
            setRolesError(true);
        }else if(firstName === '' || lastName === '' || email === ''){
            toast.error(`${t('Please fill in all required fields')}!`);
        }else{
            setRolesError(false);
            setError2(false);
            if (password !== confirmPassword) {
                setError(true);
            }else{
                setError(false);
                setBlocking(true);
                const token = localStorage.getItem("token");
                try {
                    const userData = {
                        user_id: userId,
                        first_name: firstName,
                        sir_name: lastName,
                        language: language,
                        industry: industry,
                        other_industry: otherIndustry,
                        email: email,
                        password: password,
                        confirm_password: confirmPassword,
                        roles : roles
                    };
                    if (isDobModified) {
                        userData.date_of_birth = selectedDate;
                    }
                    // console.log(userData);
                    const response = await axios.post(
                        `${apiUrl}/update-profile`,
                        userData,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                "accept-language": language,
                                "Content-Type": "application/json"
                            }
                        }
                    );
            
                    // console.log(response.data);
                    if (response.data.result === 'success') {
                        localStorage.setItem('profileComplete', 'complete');
                        fetchUserData();
                        setBlocking(false);
                        setLoading(false);

                        if (!response.data.data.user.email_verified) {
                            console.log('email_verified : ', response.data.data.user.email_verified);
                            toast.warn('You have changed your email address please verfiy your new email!');
                            navigate('/verify-email');
                            return;
                        }

                        localStorage.setItem('userid',response.data.data.userId);
                        const userLanguage = response.data.data.user.language;
                        localStorage.setItem('userRoles',response.data.data.roles);
                        localStorage.setItem('user', JSON.stringify(response.data.data.user));
                        console.log('selected language : ', userLanguage);
                        if (userLanguage === 'english') {
                            localStorage.setItem('language','en');
                        }else if(userLanguage === 'french'){
                            localStorage.setItem('language','fr');
                        }
                        // Handle success
                        toast.success(response.data.message);
                        navigate('/casting-room');
                        
                    } else {
                        // Handle error
                        setBlocking(false);
                        setLoading(false);
                        console.error('Update failed:', response.data.message);
                        toast.error(response.data.message);
                    }

                    if(response.data.result === 'error'){
                        toast.error(response.data.message);
                        setBlocking(false);
                        console.error('Update failed!');
                    }

                } catch (error) {
                    setBlocking(false);
                    console.error('Error updating user:',  error.message);
                    toast.error(error.response?.data?.message);
                    if(error.response?.data?.message === 'Token expired'){
                        navigate('/login');
                        console.log('navigate state');
                    }
                }
            }
        }
    }
    //--------- Update user data end


    return (
        <>
            <BlockUi tag="div" blocking={blocking}>
                <Navbar authorized={true} />

                {/*/--- setting wrapper ---/*/}
                <section className='settings-wrapper'>
                    <CContainer>
                        <CRow>
                            <CCol lg={6} className='mx-auto'>
                                <div className='settings-card'>
                                    <h2>{t('Settings')}</h2>
                                    {!profileComplete && (
                                        <p className='warning-msg'>Complete Your Profile Before You Visit Website</p>
                                    )}
                                    {loading?
                                        <div className='' style={{ height:'70vh' }}>
                                            <Spinner/>
                                        </div>
                                    :
                                        <CForm>
                                            <div className="single-input-wrapper">
                                                <CFormLabel className="d-block">{t('First Name')}</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    placeholder={t('Name')}
                                                    value={firstName}
                                                    onChange={(e)=>setFirstName(e.target.value)}
                                                />
                                            </div>

                                            <div className="single-input-wrapper mt-4">
                                                <CFormLabel className="d-block">{t('Surname')}</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    placeholder={t('Surname')}
                                                    value={lastName}
                                                    onChange={(e)=>setLastName(e.target.value)}
                                                />
                                            </div>

                                            {/*/--- date of birth starts here ---/*/}
                                            <div className="single-input-wrapper mt-4">
                                                <label className="d-block mb-3">{t('Date of Birth')}</label>
                                                <div className='dob-input-wrapper'>
                                                    <div className='dob-input-content'>
                                                        <label className="d-block">{t('Month')}</label>
                                                        <select onChange={handleMonthChange} value={selectedDate.getMonth()}>
                                                            {[...Array(12)].map((_, index) => (
                                                                <option key={index} value={index}>{new Date(0, index).toLocaleString('default', { month: 'long' })}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='dob-input-content'>
                                                        <label className="d-block">{t('Day')}</label>
                                                        <select onChange={handleDayChange} value={selectedDate.getDate()}>
                                                            {renderDayOptions()}
                                                        </select>
                                                    </div>
                                                    <div className='dob-input-content'>
                                                        <label className="d-block">{t('Year')}</label>
                                                        <select onChange={handleYearChange} value={selectedDate.getFullYear()}>
                                                            {[...Array(200)].map((_, index) => (
                                                                <option key={index} value={currentDate.getFullYear() - index}>{currentDate.getFullYear() - index}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*/--- date of birth ends here ---/*/}

                                            {/*/--- language selector starts here ---/*/}
                                            <div className="single-input-wrapper mt-4">
                                                <CFormLabel  className="d-block">{t('Language')}</CFormLabel >
                                                <div className='language-input-wrapper d-flex align-items-center'>
                                                    <CFormCheck 
                                                        type="radio" 
                                                        name="flexRadio" 
                                                        id="flexRadioDisabled" 
                                                        label={t('English')} 
                                                        className='me-4'         
                                                        checked={language === 'english'}
                                                        onChange={() => setLanguage('english')}
                                                    />

                                                    <CFormCheck 
                                                        type="radio" 
                                                        name="flexRadio" 
                                                        id="flexRadioChecked" 
                                                        label={t('French')}
                                                        checked={language === 'french'}
                                                        onChange={() => setLanguage('french')}
                                                    />
                                                </div>
                                            </div>
                                            {/*/--- language selector ends here ---/*/}

                                            {/*/--- industry selector strats here ---/*/}
                                            <div className="single-input-wrapper mt-4">
                                                <CFormLabel  className="d-block">{t('Industry')}</CFormLabel >
                                                <div className='language-input-wrapper d-flex align-items-center'>
                                                    <CFormCheck 
                                                        type="radio" 
                                                        name="flexRadio2" 
                                                        id="flexRadioFilm" 
                                                        label={t('Film')}
                                                        className='me-4'
                                                        value={'film'}
                                                        checked={industry === 'film'}
                                                        onChange={()=>setIndustry('film')}
                                                    />
                                                    <CFormCheck 
                                                        type="radio" 
                                                        name="flexRadio2" 
                                                        id="flexRadioTV" 
                                                        label={t('TV')}
                                                        className='me-4' 
                                                        value={'TV'}
                                                        checked={industry === 'TV'}
                                                        onChange={()=>setIndustry('TV')}
                                                    />
                                                    <CFormCheck 
                                                        type="radio" 
                                                        name="flexRadio2" 
                                                        id="flexRadioOther" 
                                                        label={t('Other')}
                                                        value={'Other'}
                                                        checked={industry === 'Other'}
                                                        onChange={()=>setIndustry('Other')}
                                                    />
                                                </div>
                                                {industry === 'Other' && (
                                                    <div className='other-industry-wrapper mt-4'>
                                                        <CFormInput 
                                                            type="text" 
                                                            name="" 
                                                            placeholder={t('Type your industry')} 
                                                            value={otherIndustry} 
                                                            onChange={(e) => setOtherIndustry(e.target.value)}
                                                        />
                                                        {error2?
                                                            <p className='error-msg'>{t('Other industry cannot be empty!')}</p>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                            {/*/--- industry selector ends here ---/*/}

                                            <div className="single-input-wrapper mt-4">
                                                <CFormLabel className="d-block">{t('Email')}</CFormLabel>
                                                <CFormInput
                                                    type="email"
                                                    placeholder={t('Email')}
                                                    value={email}
                                                    onChange={(e)=>setEmail(e.target.value)}
                                                />
                                            </div>

                                            
                                            {/*/-- user roles --/*/}
                                            <div className="setting-form-input mt-4">
                                                <CFormLabel className="d-block">{t('Select Roles')}*</CFormLabel >
                                                <Select
                                                    isMulti
                                                    name="roles"
                                                    options={roleOptions}
                                                    className="select-input roles-select-wrapper"
                                                    classNamePrefix="select"
                                                    placeholder={`${t('Select Roles')}*`}
                                                    onChange={handleRoleChange}
                                                    value={roleOptions.filter(option => roles.includes(option.value))}
                                                />
                                            </div>
                                            {rolesError &&(
                                                <p className='error-msg'>{t('Roles cannot be empty!')}</p>
                                            )}

                                            <div className="single-input-wrapper mt-4">
                                                <CFormLabel className="d-block">{t('Password')}</CFormLabel >
                                                <CFormInput 
                                                    type="password" 
                                                    name="" 
                                                    placeholder={t('Password')}
                                                    value={password} 
                                                    onChange={(e) => setPasword(e.target.value)}
                                                />
                                            </div>

                                            <div className="single-input-wrapper mt-4">
                                                {error?
                                                    <p className='error-msg'>{t('Password does not match')}!</p>
                                                    :
                                                    <></>
                                                }
                                                <CFormLabel className="d-block">{t('Confirm Password')}</CFormLabel >
                                                <CFormInput 
                                                    type="password" 
                                                    name="" 
                                                    placeholder={t('Confirm Password')}
                                                    value={confirmPassword} 
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                            </div>
                                            
                                            <div className="login-btn-wrapper mt-5">
                                                <CButton className='primary_btn' onClick={handleUserUpdate}>
                                                    {t('Update')}
                                                </CButton>
                                            </div>
                                        </CForm>
                                    }
                                </div>
                            </CCol>
                        </CRow>
                    </CContainer>
                </section>
                {/*/--- setting wrapper end's here ---/*/}

                <Footer/>
                <CopyRight/>

                {/*/--- alert modal ---/*/}
                <CModal
                    alignment="center"
                    visible={alertvisible}
                    onClose={() => setAlertVisible(false)}
                    aria-labelledby="VerticallyCenteredExample"
                    className='confirmation-modal'
                >
                    <CModalBody className='pt-5'>
                        <p className='confirmation-txt'>{t("You can only select up to 3 roles.")}</p>
                    </CModalBody>
                    <CModalFooter>
                        <CButton className='confirmation-btn primary_btn' onClick={() => setAlertVisible(false)}>{t('OK')}</CButton>
                    </CModalFooter>
                </CModal>
            </BlockUi>
        </>
    )
}

export default Settings;