import React, {useEffect, useState} from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';

function Notifications() {
    
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token");
    const [notifications, setNotifications] = useState([]);
    const navigate = useNavigate();

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${apiUrl}/notifications`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.result === "success") {
                setNotifications(response.data.data);
                console.log('notifications fetched successfully.');
            }else{
                console.log(response.data.message);
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
            if (error.response && error.response.status === 401) {
                console.error("Unauthorized access, redirecting to login.");
                navigate('/login');
            } else {
                console.error("Error fetching notifications:", error);
            }
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            {/* notifications section */}
            <section className='notification-wrapper'>
                <CContainer className="h-100-p">
                    <CRow className="align-items-center h-100-p">
                        <CCol md={12} lg={10} className='mx-auto'>
                            {notifications.length === 0 ? (
                                <div className='no-notification text-white'>No Notifications Found!</div>
                            ) : (
                                notifications.map(notification => (
                                    <div key={notification._id} className='notification-card'>
                                        <div className='notification-title'>{notification.title}</div>
                                        <div className='notification-msg'>{notification.message}</div>
                                    </div>
                                ))
                            )}
                        </CCol>
                    </CRow>
                </CContainer>
            </section>
            
            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default Notifications;