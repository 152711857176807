import React, { useState, useEffect,useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { 
    CNavbar, 
    CNavbarNav,
    CForm, 
    CNavLink, 
    CNavItem,
    CCollapse,
    CNavbarToggler,
    CNavbarBrand,
    CContainer,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem
} from '@coreui/react';
import logo from '../assets/images/main-logo.png';
import i18n from '../language/Converter';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { format } from 'date-fns';

function Navbar(props) {
    const [visible, setVisible] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [selected, setSelected] = useState(() => {
        const language = localStorage.getItem('language');
        return language === 'fr' ? 'FR' : 'US';
    });
    const [userLanguage, setUserLanguage] = useState('');
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const [profileMenu, setProfileMenu] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownRef2 = useRef(null);
    const [notificationMenu, setNotificationMenu] = useState(false);
    const [activeProfileItem, setActiveProfileItem] = useState(localStorage.getItem('activeProfileItem') || '');
    const token = localStorage.getItem("token");
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [Unauthorized, setUnauthorized] = useState(false);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setProfileMenu(false);
        }
    };

    const handleClickOutside2 = (event) => {
        if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setNotificationMenu(false);
        }
    };

    const combinedHandler = (event) => {
        handleClickOutside(event);
        handleClickOutside2(event);
    };

    useEffect(() => {
        document.addEventListener('mousedown', combinedHandler);
        return () => {
            document.removeEventListener('mousedown', combinedHandler);
        };
    }, []);

    const isActive = (path) => {
        return location.pathname === path;
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        setIsLoggedIn(!!token);
    }, []);  
    
    useEffect(() => {
        const language = localStorage.getItem('language');
        i18n.changeLanguage(language);
    }, []);

    function handleClick(lang) {
        setSelected(lang);
        // console.log(selected);
        
        localStorage.setItem('language', lang);
        
        const language = localStorage.getItem('language');
        i18n.changeLanguage(language);
        // console.log('language nav', language);
    }

    const language = localStorage.getItem('language');
    
    //--- logout function
    const handleLogout = async () => {
        setProfileMenu(!profileMenu);
        const language = localStorage.getItem("language");
        if (language === 'english') {
            setUserLanguage('en');
        } else {
            setUserLanguage('fr');
        }
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'accept-language': userLanguage
                }
            };
            const response = await fetch(`${apiUrl}/logout`, config);
            // console.log(response.data.message);
            localStorage.removeItem('token');
            localStorage.removeItem('userid');
            localStorage.removeItem('userImg');
            localStorage.removeItem('user');
            localStorage.removeItem('profile');
            localStorage.removeItem('language');
            localStorage.removeItem('userRoles');
            localStorage.removeItem('profileComplete');
            navigate('/login');
        
        } catch (error) {
            console.error('Logout failed', error.response || error);
            toast.error(error.response.data.message);
        }
    }

    const handleProfileItemClick = (item) => {
        setActiveProfileItem(item);
        localStorage.setItem('activeProfileItem', item);
        setProfileMenu(false);
    }

    
    //--- set notification
    const handleNotificationMenu = () => {
        setNotificationMenu(!notificationMenu);
        setProfileMenu(false);
        readNotifications();
    }

    const handleProfileMenu = () => {
        setProfileMenu(!profileMenu);
        setNotificationMenu(false);
    }

    //--- fetching notification
    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${apiUrl}/notifications`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.result === "success") {
                const fetchedNotifications = response.data.data;
                setNotifications(response.data.data);
                // Count the number of unread notifications
                const unreadCount = fetchedNotifications.filter(notification => notification.read_at === null).length;
                setUnreadCount(unreadCount);
                // console.log('notifications fetched successfully.');
            }else{
                console.log(response.data.message);
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
            if (error.response && error.response.status === 401) {
                console.error("Unauthorized access.");
                setUnauthorized(true);
                if (props.authorized) {
                    navigate('/login');
                }

            } else {
                console.error("Error fetching notifications:", error);
            }
        }
    };

    //--- read all notifications
    const readNotifications = async () => {
        try {
            const response = await axios.get(`${apiUrl}/notification/read-all`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.result === "success") {
                // console.log(response.data.message);
                fetchNotifications();
            }else{
                console.log(response.data.message);
            }
        } catch (error) {
            console.error("Error reading notifications:", error);
        }
    };

    // Clear all notifications
    const clearAllNotifications = async () => {
        try {
            const response = await axios.get(`${apiUrl}/notification/clear-all`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.result === "success") {
                toast.success(response.data.message);
                // console.log(response.data.message);
                fetchNotifications();
            }else{
                toast.error(response.data.message);
                // console.log(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.error("Error reading notifications:", error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd');
    };

    return (
        <>
            <CNavbar expand="lg" className="header-navbar">
                <CContainer>
                    <CNavbarBrand as={NavLink} to="/">
                        <img src={logo} alt="logo"/>
                    </CNavbarBrand>
                    <CNavbarToggler onClick={() => setVisible(!visible)} />
                    <CCollapse className="navbar-collapse" visible={visible}>
                        <CNavbarNav className='mx-auto'>
                            <CNavItem>
                                <CNavLink as={Link} to="/casting-room" className={isActive('/casting-room') ? 'active' : ''}>
                                    Casting Room
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink as={Link} to="/film-booth" className={isActive('/film-booth') ? 'active' : ''}>Film Pitching Booth</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink as={Link} to="/video-hub" className={isActive('/video-hub') ? 'active' : ''}>Video HUB</CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink as={Link} to="/cinematheque" className={isActive('/cinematheque') ? 'active' : ''}>Cinematheque</CNavLink>
                            </CNavItem>
                        </CNavbarNav>
                        <CForm className="d-flex">
                            
                            {language === 'fr' ?
                                <Link className='language-input ms-3 ms-lg-0' onClick={(e) => handleClick('en')}>English</Link>
                                :
                                <Link className='language-input ms-3 ms-lg-0' onClick={(e) => handleClick('fr')}>Français</Link>
                            }

                            {/*/--- notification menu ---/*/}
                            {isLoggedIn ? 
                                <>
                                    {/* notification link */}
                                    <Link ref={dropdownRef2} className='mx-2 profile-link notification-link' onClick={()=>handleNotificationMenu()}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path className='nav_icons' d="M6.61554 19H12.6155M15.6155 6.6C15.6155 5.11479 14.9834 3.69041 13.8581 2.6402C12.7329 1.59 11.2068 1 9.61554 1C8.02424 1 6.49812 1.59 5.3729 2.6402C4.24769 3.69041 3.61554 5.11479 3.61554 6.6C3.61554 9.2862 2.93935 11.1835 2.143 12.4866C1.3717 13.7486 0.986055 14.3797 1.00039 14.5436C1.0165 14.7277 1.05284 14.7925 1.20157 14.9023C1.33396 15 1.96317 15 3.2216 15H16.0095C17.2679 15 17.8971 15 18.0295 14.9023C18.1782 14.7925 18.2146 14.7277 18.2307 14.5436C18.245 14.3797 17.8593 13.7486 17.0881 12.4866C16.2917 11.1835 15.6155 9.2862 15.6155 6.6Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>

                                        {unreadCount > 0 && <div className='notificatin-counter'>{unreadCount}</div>}

                                        {/*/--- notification dropdown ---/*/}
                                        <div className={`notification-dropdown ${notificationMenu ? 'd-block' : 'd-none'}`}>
                                            <ul>
                                                {notifications.length === 0 ? (
                                                    <div className='w-100 text-center text-white py-5'>No Notifications Found!</div>
                                                ) : (
                                                    <>
                                                        <Link className='noti-del-btn d-block text-end pt-3 pe-3' onClick={() => clearAllNotifications()}>{t('Clear All')}</Link>
                                                        {[...notifications].reverse().map(notification => {
                                                            let linkTo = '';
                                                            switch (notification.type) {
                                                                case 'new-message':
                                                                    linkTo = '/chat-threads';
                                                                    break;
                                                                case 'filmpitching':
                                                                    linkTo = '/user-film-booth';
                                                                    break;
                                                                case 'audition':
                                                                    linkTo = `/user-auditions/${notification.data.casting_call_id}`;
                                                                    break;
                                                                default:
                                                                    linkTo = '/'; // fallback link in case of unknown notification type
                                                                    break;
                                                            }

                                                            return (
                                                                <li className='notification-item' key={notification._id}>
                                                                    <Link to={linkTo}>
                                                                        <div className='notification-title'>{notification.title}</div>
                                                                        <div className='notification-msg'>{notification.message} <span className='notification-time ms-1'>{formatDateTime(notification.createdAt)}</span></div>
                                                                    </Link>
                                                                </li>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                    </Link>
                                </>
                                :
                                <></> 
                            }

                            {/*/--- profile menu ---/*/}
                            <Link ref={dropdownRef} onClick={()=>handleProfileMenu()} className='ms-3 profile-link'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className='nav_icons' d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path className='nav_icons' d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                {/* profile dropdown start here */}
                                <div className={`profile-dropdown-wrapper ${profileMenu ? 'd-block' : 'd-none'}`}>
                                    <div className='profile-dropdown-content'>
                                    <ul>
                                        {isLoggedIn?
                                        <>
                                            <li className={`profile-dropdown-item ${isActive('/edit-user-profile') ? 'profile-dropdown-active' : ''}`}>
                                                <Link to='/edit-user-profile'>Profile</Link>
                                            </li>
                                            <li className={`profile-dropdown-item ${isActive('/casting-room') ? 'profile-dropdown-active' : ''}`}>
                                                <Link to='/casting-room#casting-calls-wrapper'>Auditions</Link>
                                            </li>
                                            <li className={`profile-dropdown-item ${isActive('/casting-calls-creation') ? 'profile-dropdown-active' : ''}`}>
                                                <Link to='/casting-calls-creation'>Create Casting Call</Link>
                                            </li>
                                            <li className={`profile-dropdown-item ${isActive('/film-booth') ? 'profile-dropdown-active' : ''}`}>
                                                <Link to='/film-booth#profiles-wrapper'>Explore & Pitch</Link>
                                            </li>
                                            <li className={`profile-dropdown-item ${isActive('/user-film-booth') ? 'profile-dropdown-active' : ''}`}>
                                                <Link to='/user-film-booth'>Pitching Box</Link>
                                            </li>
                                            <li className={`profile-dropdown-item ${isActive('/membership') ? 'profile-dropdown-active' : ''}`}>
                                                <Link to='/membership'>My Membership</Link>
                                            </li>
                                            <li className={`profile-dropdown-item ${isActive('/chat-threads') ? 'profile-dropdown-active' : ''}`}>
                                                <Link to='/chat-threads'>
                                                    Connect
                                                </Link>
                                            </li>
                                            <li className={`profile-dropdown-item ${isActive('/settings') ? 'profile-dropdown-active' : ''}`}>
                                                <Link to='/settings'>
                                                    Settings
                                                </Link>
                                            </li>
                                            <li className='profile-dropdown-item auth-btns py-2' style={{ borderBottom: '0' }}>
                                                <Link onClick={handleLogout} className='logout-btn'>Logout</Link>
                                            </li>
                                        </>
                                        : 
                                        <li className='profile-dropdown-item auth-btns' style={{ borderBottom:'0' }}>
                                            <Link to='/login' className='logout-btn'>Login</Link>
                                        </li>
                                        }
                                    </ul>
                                    </div>
                                </div>
                                {/* profile dropdown end's here */}

                            </Link>
                
                        </CForm>
                    </CCollapse>
                </CContainer>
            </CNavbar>
        </>
    )
}

export default Navbar;
