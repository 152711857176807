import { CButton, CCol, CContainer, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from "../components/loader/Spinner";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';

function EmailVerify() {

    const { t } = useTranslation();
    const location = useLocation();
    const [blocking, setBlocking] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [emailVerfied, setEmailVerfified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [emailMsg, setEmailMsg] = useState('');
    const [isToken, setIsToken] = useState(false);

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/');
    }

    useEffect(() => {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        if (token) {
            verifyEmail(token);
            setIsToken(true);
        }else{
            console.log('Token not found.');
            console.log(isToken);
            setLoading(false);
        }
    }, [location.search]);


    const verifyEmail = async (token) => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/verify-email`, {
                params: { token }
            });
            if (response.data.result === 'success') {
                setEmailVerfified(true);
                setLoading(false);
                navigate('/login');
            }else{
                console.log('email verfiying error.');
                setEmailMsg(response.data.message);
                toast.error(response.data.message);
            }
            console.log("response :" , response);
            console.log('Email verified!');
            setLoading(false);
        } catch (err) {
            console.log('Error verifying Email : ', err);  
            if (err.response) {
                toast.error(err.response?.data?.message);
            }else{
                toast.error(err.message);
            }
            
            setEmailMsg(err.response?.data?.message);
            setLoading(false);
        }
    };

    const handleResendEmail = async () => {
        setBlocking(true);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (email === '') {
            toast.error(`${t('Please enter email address!')}`);
            setBlocking(false);
        } else if (!emailRegex.test(email)) {
            toast.error(`${t('Please enter a valid email address!')}`);
            setBlocking(false);
        } else {
            try {
                const response = await axios.post(`${apiUrl}/resend-verification-email`, 
                    { email: email }, 
                    {
                        headers: { 
                            'Content-Type': 'application/json'
                        }
                    }
                );
    
                if (response.data.result === 'success') {
                    toast.success(`${t('Email Resent Successfully.')}`);
                    setEmail('');
                } else {
                    toast.error(response.data.message || `${t('Error in resending email.')}`);
                }
                setBlocking(false);
                console.log("response:", response);
            } catch (err) {
                console.log('Error resending email:', err);
                toast.error(`${t('An error occurred while resending the email.')}`);
                setBlocking(false);
            }
        }
    };
    

    return (
        <>
            <BlockUi tag="div" blocking={blocking}>
                <div className='email-template-wrapper'>
                    <CContainer className='h-100'>
                        
                        <CRow className='align-items-center h-100 justify-content-center'>
                            <CCol lg={6}>
                                <div className='site-logo'>
                                    <Link to='/'>
                                        <img src={logo} alt='logo' />
                                    </Link>
                                </div>
                            </CCol>
                            {loading?
                                <CCol lg={6}>
                                    <Spinner/>
                                </CCol>
                            :
                                <CCol lg={6} className='text-center'>
                                    {emailVerfied? 
                                        <h2 className='text-start'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                                            </svg>
                                           { t('Email Verified successfully!')}
                                        </h2>
                                        :
                                        <>
                                            {isToken && (
                                                <h2>{emailMsg}</h2>
                                            )}
                                        </>
                                        
                                    }


                                    <p className='text-start'>
                                        Your {emailVerfied? 
                                        'Email Verified successfully! If you still face any issues with verification then resubmit your email address to verfify again.' 
                                        : 
                                        `Email Not Verified!, Please resend your email address to verify.`}
                                    </p>

                                    <div className='single-input-wrapper mt-4'>
                                        <CFormLabel className="d-block">{t('Email')}</CFormLabel>
                                        <CFormInput
                                            type="text"
                                            placeholder={t('Email')}
                                            value={email}
                                            onChange={(e)=>setEmail(e.target.value)}
                                        />
                                    </div>
                                    <CButton className='primary_btn mt-4 w-100' onClick={handleResendEmail}>{t('Resend Email')}</CButton>    
                                    {/* <div className='mt-3 w-100'>
                                        <CButton className='primary_btn mt-3 w-100' onClick={handleNavigate}>{t('Back to Home page')}</CButton>    
                                    </div> */}
                                </CCol>
                            }
                        </CRow>
                        
                    </CContainer>
                </div>
            </BlockUi>
        </>
    )
}

export default EmailVerify;