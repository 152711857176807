import React, { useEffect, useState } from 'react';
import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import SuccessStoryCard from '../components/SuccessStoryCard';
import axios from 'axios';

function SuccessStories() {

    const { t } = useTranslation();
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const imgeUrl = process.env.REACT_APP_IMAGE_URL;
    const [stories, setStories] = useState([]);
    const [successStoryImg, setSuccessStoryImg] = useState(false);
    const [successStoryVid, setSuccessStoryVid] = useState(false);
  
    useEffect(() => {
      window.scrollTo(0, 0);
      fetchStories();
    }, []);
  

    const fetchStories = async () => {
      try {
        const language = localStorage.getItem("language");
        const response = await axios.get(`${apiUrl}/success-stories`,{
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            'accept-language': language
            }
        });
        if(response.data.result === 'success'){
            setStories(response.data.data);
        }
       
      } catch (error) {
        console.error('Error fetching success stories:', error);
      }
    };

    
    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            {/*/--- banner section ---/*/}
            <section className='banner-wrapper'>
                <div className='banner-wraper-inner-layer h-100-p'>
                    <CContainer className="h-100-p">
                        <CRow className="align-items-center h-100-p">
                            <CCol md={12}>
                                <div className='banner-content text-center'>
                                    <h2>
                                        {t('pitch your')}<br/>
                                        {t('film')}
                                    </h2>
                                    <p>{t('Enter Our Film Pitching Booth Now')}</p>
                                    <CButton className='explore-btn mt-5'>{t('Enter')}</CButton>
                                </div>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </section>

            {/*/--- success stories ---/*/}
            <section className='success-stories-wrapper'>
                <CContainer className="h-100-p">
                    <CRow className="align-items-center h-100-p">
                        <CCol md={12}>
                            <div className='success-stories-content mb-5'>
                                <h2 className='section-heading'>{t('success')}</h2>
                                <h3 className='section-sub-heading'>{t('stories')}</h3>
                            </div>
                        </CCol>
                        {stories.map(story => {
                            // Print the display_type in the console
                            // console.log("display_type in parent:", story.display_type);

                            return (
                            <CCol md={12} lg={6} key={story._id}>
                                <SuccessStoryCard
                                    imgSrc={story.image ? `${imgeUrl}/${story.image}` : null}
                                    videoSrc={story.video ? `${imgeUrl}/${story.video}` : null}
                                    title={story.name}
                                    description={story.description}
                                    location={story.location}
                                    datetime={story.datetime}
                                    displayType={story.display_type}
                                />
                            </CCol>
                            );
                        })}
                    </CRow>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default SuccessStories;
