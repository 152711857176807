import React, { useEffect } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';

function TermsOfUse() {

    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            {/*/--- banner section ---/*/}
            {/* <section className='home-banner video-hub-banner'>
                <div className='video-banner-layer'>
                    <CContainer className="h-100-p">
                        <CRow className="align-items-center h-100-p">
                            <CCol md={12}>
                                <div className='home-banner-wrapper text-center'>
                                    <h2>
                                        {t('terms of service')}
                                    </h2>
                                </div>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </section> */}

            {/*/---- Terms of use section ----/*/}
            <section className='privacy-policy-wrapper'>
                <CContainer>
                    <CRow>
                        <CCol md={12}>
                            <div className='privacy-policy-wrapper-content w-100'>
                                <div className='privacy-policy-card'>
                                    <div className="section-header mb-5">
                                        <h2 className="section-heading">{t('Terms')}</h2>
                                        <h3 className="section-sub-heading">{t('of Use')}</h3>
                                    </div>

                                    <div className='policy-content'>
                                        <h3>{t('Introduction')}</h3>
                                        <p>
                                            {t("Welcome to Cinewoo! These Terms of Use ('Terms') govern your use of our website and services. By accessing or using our services, you agree to be bound by these Terms.")}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Eligibility')}</h3>
                                        <p>
                                            {t('You must be at least 18 years old to use our services. By using our services, you represent and warrant that you meet this age requirement.')}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Account Registration')}</h3>
                                        <p>
                                            {t('To access certain features of our platform, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.')}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('User Responsibilities')}</h3>
                                        <p>
                                            {t('As a user of our platform, you agree to:')}
                                        </p>
                                        <ul>
                                            <li>
                                                <span>- </span>
                                                {t('Use the platform in compliance with all applicable laws and regulations.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Not use the platform for any unlawful or fraudulent purposes.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Not interfere with or disrupt the integrity or performance of the platform.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Respect the intellectual property rights of others.')}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Content Ownership')}</h3>
                                        <p>
                                            {t('All content on the platform, including text, graphics, logos, images, video, audio and software, is the property of Cinewoo or its licensors and is protected by intellectual property laws. You may not use, reproduce, distribute, or create derivative works of any content without our prior written permission.')}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('User-Generated Content')}</h3>
                                        <p>
                                            {t('You retain ownership of any content you submit, post, or display on or through the platform including your personal profile page. By submitting content, you grant Cinewoo a worldwide, non-exclusive, royalty-free license to use, copy, modify, and display your content in connection with the operation of the platform.')}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Termination')}</h3>
                                        <p>
                                            {t('We reserve the right to suspend or terminate your account and access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the platform, us, or third parties, or for any other reason.')}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Disclaimer of Warranties')}</h3>
                                        <p>
                                            {t("Our services are provided 'as is' and 'as available' without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.")}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Limitation of Liability')}</h3>
                                        <p>
                                            {t('To the fullest extent permitted by law, Cinewoo shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues,')}
                                            {t('whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the platform; (ii) any unauthorized access ')}
                                            {t('to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the platform; (iv) any bugs, viruses,')}
                                            {t('trojan horses, or the like that may be transmitted to or through our platform by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the ')}
                                            {t('use of any content posted, emailed, transmitted, or otherwise made available through the platform; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.')}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Governing Law')}</h3>
                                        <p>
                                            {t('These Terms shall be governed and construed in accordance with the laws of the Province of Ontario, without regard to its conflict of law provisions.')}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Changes to These Terms')}</h3>
                                        <p>
                                            {t('We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page.')}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Contact Us')}</h3>
                                        <p>
                                            {t('If you have any questions about these Terms, please contact us at:')}
                                            <Link to=''>info@cinewoo.com</Link> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default TermsOfUse;
