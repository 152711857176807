import React, { useState } from 'react';
import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import PackageCard from '../components/PackageCard';

function Membership() {

    const [blocking, setBlocking] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
   
    const handleNavigate = () => {
        navigate('/subscription');
    }
    
    return (
        <>
            <BlockUi tag="div" blocking={blocking}>
                {/*/--- navbar ---/*/}
                <Navbar authorized={true} />

                <CContainer>
                    <div className='membership-wrapper'>
                        <CRow>
                            <CCol lg={10} className='mx-auto'>
                                <PackageCard durationTxt={t('community members receive')} setBlocking={setBlocking} />
                                <div className='d-flex w-100'>
                                    <div className='w-50 p-2'>
                                        <CButton className='secondary_outline_btn w-100'>{t('Cancel')}</CButton>
                                    </div>
                                    <div className='w-50 p-2'>
                                        <CButton className='primary_btn w-100' onClick={handleNavigate}>{t('Upgrade Now')}</CButton>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                    </div>
                </CContainer>

                {/*/--- Footer ---/*/}
                <Footer />

                {/*/--- copy right ---/*/}
                <CopyRight />
            </BlockUi>
        </>
    )
}

export default Membership;
