import React, { useState } from 'react';
import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/footer-logo.png';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from "react-toastify";
import Spinner from './loader/Spinner';
function Footer() {

    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [blocking, setBlocking] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const handleSubmit = async () => {
        setBlocking(true);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === '') {
            toast.error('Please fill in email input first!');
            setEmailError(true);
            setBlocking(false);
        } else if (!emailRegex.test(email)) {
                // Validate if email is in the correct format
                toast.error('Please enter a valid email address!');
                setBlocking(false);
                setEmailError(true);
        }else{
            setEmailError(false);
            try {
                const language = localStorage.getItem("language");
                const token = localStorage.getItem("token");
                const response = await axios.post(`${apiUrl}/add_audience_user`, {
                    email: email,
                    subject: 'Welcome to Cinewoo',
                    text: 'Thank you for joining Cinewoo!'
                }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'accept-language': language,
                    'Authorization': `Bearer ${token}`
                }
                });
        
                if (response.status === 200) {
                    setBlocking(false);
                    // console.log(response.data.message);
                    toast.success(response.data.message);
                    // console.log('success');
                    setEmail('');
                } else {
                    setBlocking(false);
                    toast.error(response.data.error.detail);
                    console.log('error');
                    console.log(response.data);
                }
        
            } catch (error) {
                setBlocking(false);
                if (error.response && error.response.data) {
                    const errorMessage = error.response.data.error.detail;
                    console.error('Error while subscribe:', errorMessage);
                    toast.error(errorMessage);
                } else {
                    console.error('Error while subscribe:', error.message);
                    toast.error(error.message);
                }
            }
        }
    }

    return (
        <>
            <section className='footer-wrapper'>
                <CContainer className="h-100-p">
                    <CRow className="h-100-p">
                        <CCol lg={3} md={12}>
                            <Link className='footer-logo'>
                                <img src={logo} alt='logo'/>
                            </Link>
                        </CCol>
                        <CCol lg={3} md={6} sm={6} xs={6}>
                            <ul className='mt-4 mt-md-0 mt-lg-0'>
                                <li><Link to='/casting-room'>Casting Room</Link></li>
                                <li><Link to='/film-booth'>Film Pitching Booth</Link></li>
                                <li><Link to='/video-hub'>Video HUB</Link></li>
                                <li><Link to='/cinematheque'>Cinematheque</Link></li>
                            </ul>
                        </CCol>
                        <CCol lg={2} md={6} sm={6} xs={6}>
                            <ul className='mt-4 mt-md-0 mt-lg-0'>
                                <li><Link to=''>{t('community')}</Link></li>
                                <li><Link to=''>{t('Partners')}</Link></li>
                                <li><Link to=''>{t('contribute')}</Link></li>
                                <li><Link to='/contact-us'>{t('Contact')}</Link></li>
                            </ul>
                        </CCol>
                        <CCol lg={4} md={12} sm={12} xs={12}>
                            <h4 className='social mt-md-0 mt-4 mt-lg-0'>{t('Subscribe')}</h4>
                            <ul className=''>
                                <li>
                                    <Link to='https://www.instagram.com/cinewoo/?hl=en' className='insta-icon'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.875 3H11.125C6.63838 3 3 6.63838 3 11.125V20.875C3 25.3616 6.63838 29 11.125 29H20.875C25.3616 29 29 25.3616 29 20.875V11.125C29 6.63838 25.3616 3 20.875 3ZM26.5625 20.875C26.5625 24.0112 24.0112 26.5625 20.875 26.5625H11.125C7.98875 26.5625 5.4375 24.0112 5.4375 20.875V11.125C5.4375 7.98875 7.98875 5.4375 11.125 5.4375H20.875C24.0112 5.4375 26.5625 7.98875 26.5625 11.125V20.875Z" fill="#B0B0B0"/>
                                            <path d="M16 9.5C12.4104 9.5 9.5 12.4104 9.5 16C9.5 19.5896 12.4104 22.5 16 22.5C19.5896 22.5 22.5 19.5896 22.5 16C22.5 12.4104 19.5896 9.5 16 9.5ZM16 20.0625C13.7607 20.0625 11.9375 18.2393 11.9375 16C11.9375 13.7591 13.7607 11.9375 16 11.9375C18.2393 11.9375 20.0625 13.7591 20.0625 16C20.0625 18.2393 18.2393 20.0625 16 20.0625Z" fill="#B0B0B0"/>
                                            <path d="M22.9872 9.87873C23.4656 9.87873 23.8533 9.49096 23.8533 9.01261C23.8533 8.53426 23.4656 8.14648 22.9872 8.14648C22.5089 8.14648 22.1211 8.53426 22.1211 9.01261C22.1211 9.49096 22.5089 9.87873 22.9872 9.87873Z" fill="#B0B0B0"/>
                                        </svg>
                                    </Link>
                                    <Link to='https://www.facebook.com/cinewoo' className='fb-icon'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.75 3H6.25C4.45762 3 3 4.45762 3 6.25V25.75C3 27.5424 4.45762 29 6.25 29H16V20.0625H12.75V16H16V12.75C16 10.0574 18.1824 7.875 20.875 7.875H24.125V11.9375H22.5C21.603 11.9375 20.875 11.853 20.875 12.75V16H24.9375L23.3125 20.0625H20.875V29H25.75C27.5424 29 29 27.5424 29 25.75V6.25C29 4.45762 27.5424 3 25.75 3Z" fill="#B0B0B0"/>
                                        </svg>
                                    </Link>
                                    <Link to='https://www.linkedin.com/company/cinewoo/?viewAsMember=true' className='linkedin-icon'>
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.75043 23.1328H4.9223V10.7734H8.75043V23.1328ZM9.13239 6.83572C9.13239 5.59821 8.12836 4.59375 6.89148 4.59375C5.6499 4.59375 4.64844 5.59821 4.64844 6.83572C4.64844 8.07367 5.6499 9.07812 6.89148 9.07812C8.12836 9.07812 9.13239 8.07367 9.13239 6.83572ZM23.0781 16.3332C23.0781 13.0154 22.3772 10.5547 18.5015 10.5547C16.6391 10.5547 15.389 11.4861 14.8786 12.4547H14.875V10.7734H11.1562V23.1328H14.875V16.9963C14.875 15.3892 15.2841 13.8323 17.2761 13.8323C19.241 13.8323 19.3047 15.6701 19.3047 17.0984V23.1328H23.0781V16.3332ZM28 24.7188V3.28125C28 1.47186 26.5281 0 24.7188 0H3.28125C1.47186 0 0 1.47186 0 3.28125V24.7188C0 26.5281 1.47186 28 3.28125 28H24.7188C26.5281 28 28 26.5281 28 24.7188ZM24.7188 2.1875C25.3218 2.1875 25.8125 2.67819 25.8125 3.28125V24.7188C25.8125 25.3218 25.3218 25.8125 24.7188 25.8125H3.28125C2.67819 25.8125 2.1875 25.3218 2.1875 24.7188V3.28125C2.1875 2.67819 2.67819 2.1875 3.28125 2.1875H24.7188Z" fill="#B0B0B0"/>
                                        </svg>
                                    </Link>
                                    <Link to=''>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M28 3H21.9651L13 18.8064L18.7766 29H24.8114L19.0349 18.8064L28 3Z" fill="#B0B0B0"/>
                                            <path d="M10.7124 8H5.03031L8.31796 13.8354L4 21H9.68204L14 13.8354L10.7124 8Z" fill="#B0B0B0"/>
                                        </svg>
                                    </Link>
                                    <Link to='' className='youtube-icon'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M30.64 7.12C29.772 5.576 28.83 5.292 26.912 5.184C24.996 5.054 20.178 5 16.004 5C11.822 5 7.002 5.054 5.088 5.182C3.174 5.292 2.23 5.574 1.354 7.12C0.46 8.662 0 11.318 0 15.994C0 15.998 0 16 0 16C0 16.004 0 16.006 0 16.006V16.01C0 20.666 0.46 23.342 1.354 24.868C2.23 26.412 3.172 26.692 5.086 26.822C7.002 26.934 11.822 27 16.004 27C20.178 27 24.996 26.934 26.914 26.824C28.832 26.694 29.774 26.414 30.642 24.87C31.544 23.344 32 20.668 32 16.012C32 16.012 32 16.006 32 16.002C32 16.002 32 15.998 32 15.996C32 11.318 31.544 8.662 30.64 7.12ZM12 22V10L22 16L12 22Z" fill="#B0B0B0"/>
                                        </svg>
                                    </Link>
                                </li>
                            </ul>

                            <div className='subscription-method mt-4 mt-md-0 mt-lg-3'>
                                <input 
                                    type='email'  
                                    value={email} 
                                    onChange={(e)=> setEmail(e.target.value)}
                                    placeholder={t('Your Email Address')} 
                                    style={{ borderColor: emailError? 'red': 'white' }}
                                />
                                <CButton className='primary_btn' onClick={handleSubmit} disabled={blocking}>
                                    {blocking?
                                    <Spinner/>
                                    :
                                     <>{t('Send')}</>
                                    }
                                </CButton>
                                <p>{t('Your email is only used to send you the latest news from cinewoo.')}</p>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </section>
        </>
    )
}

export default Footer;
