import React from 'react';
import { CRow, CCol } from '@coreui/react';
import { useTranslation } from 'react-i18next';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

function SuccessStoryCard(props) {
  const formattedDate = formatDate(props.datetime);
  const { t } = useTranslation();
  // Debug logs
  // console.log('Image Source:', props.imgSrc);
  // console.log('Video Source:', props.videoSrc);
  // console.log("display_type : ", props.displayType);

  return (
    <div className='success-story-card mt-4'>
      <CRow>
        <CCol md={5}>
          <div className='success-img-wrapper'>
            {props.displayType === 'image' ? (
              <img src={props.imgSrc} alt='success' />
            ) : props.displayType === 'video' ? (
              <video width="100%" height="100%" controls>
                <source src={props.videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : null}
          </div>
          <div className='success-card-footer'>
            <h3 className='mt-2'>{props.title}</h3>
            <span>{t("by Admin")}</span>
          </div>
        </CCol>
        <CCol md={7}>
          <div className='success-card-txt mt-3 mt-lg-0 mb-5 mb-lg-0 pb-4 pb-lg-0'>
            <p>{props.description}</p>
          </div>
          <div className='success-card-footer date-location-footer'>
            <h5 className='mt-2'>{t("Dates & Locations")}</h5>
            <p>{formattedDate}, <span style={{ textTransform: "capitalize", color: 'rgba(72, 72, 72, 1)' }}>{props.location}</span></p>
          </div>
        </CCol>
      </CRow>
    </div>
  );
}

export default SuccessStoryCard;
