import React, { useEffect, useState } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import VideoRecorder from '../components/VideoRecorder';
import { useParams } from 'react-router-dom';
import { use } from 'i18next';

function CastingAudition() {
    
  const [blocking, setBlocking] = useState(false);
  const [castingCall, setCastingCall] = useState(null);
  const[loading, setLoading] = useState(false);
  const [isAudition, setIsAudition] = useState(false);
  const { castingCallId } = useParams();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();

  const [allRoles, setAllRoles] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    
  useEffect(() => {
    const fetchRoles = async () => {
      const language = localStorage.getItem("language");
      try {
        const response = await fetch(`${apiUrl}/roles`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'accept-language': language
          }
        });
        const data = await response.json();
        if (response.ok) {
          setAllRoles(data.data);
        } else {
          console.error('Failed to fetch roles:', data.message);
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  const fetchCastingCall = async () => {
    setLoading(true);
    try {
        const language = localStorage.getItem("language");
        const response = await fetch(`${apiUrl}/casting-call/${castingCallId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'accept-language': language
        }
        });
        const data = await response.json();
        if (response.ok) {

            const roles = data.data.roles.map(roleId => 
                allRoles.find(role => role._id === roleId)?.name || 'Role not found'
            );
            setCastingCall({ ...data.data, roles: roles });
            setIsAudition(data.data.is_audition);
            // console.log("is audition ",data.data.is_audition);
            setLoading(false);
        } else {
            console.error('Failed to fetch casting call details:', data.message);
            setLoading(false);
        }
    } catch (error) {
        console.error('Error fetching casting call details:', error);
        setLoading(false);
    }
  };
  
  useEffect(() => {
  
    if (allRoles.length > 0) {
      fetchCastingCall();
    }
  }, [id, allRoles]);


    return (
        <>
          <BlockUi tag="div" blocking={blocking}>
            {/*/--- Navbar ---/*/}
            <Navbar authorized={true}  />

            {/* casting audition section */}
            <section className='casting-audition-wrapper'>
                <CContainer className="h-100-p">
                    <CRow className="align-items-center h-100-p">
                        <CCol md={8} className='mx-auto'>
                            <VideoRecorder is_audition={isAudition} loader={loading} setBlocking={setBlocking} fetchCastingCall={fetchCastingCall} />
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
          </BlockUi>
        </>
    )
}

export default CastingAudition;
